<template>
  <div class="card-box">

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="预约" name="0">
        <div slot="label" class="tabs_label" >
          预约
          <!--            <el-badge :value="appoint_num" size="mini" class="item"></el-badge>-->
        </div>
      </el-tab-pane>
      <el-tab-pane label="预约服务记录" name="1"></el-tab-pane>
<!--      <el-tab-pane label="随访" name="2">-->
<!--        <div slot="label" class="tabs_label" >-->
<!--          随访-->
<!--          &lt;!&ndash;            <el-badge :value="follow_num" size="mini" class="item"></el-badge>&ndash;&gt;-->
<!--        </div>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="随访服务记录" name="3"></el-tab-pane>-->
    </el-tabs>


    <div class="card-content">

      <template v-if="list.length != 0">
        <el-row :gutter="100">
          <el-col :span="6" v-for="item in list" :key="item.id" >
            <div class="card"
                 @click="linkDeta(item)"
                 style="cursor: pointer"
                 :class="[
                   ( item.appointType == 0 ? 'LightBlue' : 'Violet' )
               ]">

              <div class="card-header">
                <div class="card-header-left">
                  <p>{{ item.appointType == 0 ? "体检产品" : "胃肠道产品" }}</p>
                </div>
                <div class="card-header-right">
<!--                  <p>{{ item.binded ? "已认领" : "未认领" }}</p>-->
                  <p v-if="item.status == 1">已开始</p>
                  <p v-if="item.status == 2">已完成</p>
                </div>
              </div>

              <div class="card-item">
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <p>{{ item.name }}</p>
                </el-tooltip>
                <p>{{ item.gender == 0 ? "女" : "男" }}</p>
                <p>{{ item.phoneNo }}</p>
              </div>

              <div class="card-footer">

                <div class="card-footer-left" >
                  <p class="card-footer-left-time" >{{ item.appointmentDate }}</p>
                </div>

                <div class="card-footer-right">
                  <el-button v-if="item.status == 0 && item.binded" round @click.stop="noClaim(item.id)" size="small" >取消认领</el-button>
<!--                  <p v-if="item.status == 1">已开始</p>-->
<!--                  <p v-if="item.status == 2">已完成</p>-->
                </div>

              </div>

            </div>
          </el-col>
        </el-row>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.pageNum"
            :page-sizes="[12, 24, 48, 96]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </template>

      <template v-else>
        <NoList>
          <template #text>
            <p>暂无数据</p>
          </template>
        </NoList>
      </template>

    </div>

  </div>
</template>

<script>
export default {
  name: "OperateWork",
  data() {
    return{
      activeName: 0,
      page: {
        pageNum: 1,
        pageSize: 12,
        type: '1'
      },
      total: 0,
      appoint_num: 0,
      follow_num: 0,
      list: [],
    }
  },
  created() {

    if(this.$route.query.activeName != null) {
      this.activeName= this.$route.query.activeName;
    }

    this.get_list(this.page);
  },
  mounted() {

  },
  methods: {
    // 获取列表
    get_list(y) {
      if(this.activeName == 0) {
        // 预约
        this.page.type = '1'
        this.$get("operate/appointments/page",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.total = res.data.total;
                this.list= res.data.list;
              }
            })
      }else if(this.activeName == 1) {
        // 预约服务
        this.page.type = '2'
        this.$get("operate/appointments/page",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.total = res.data.total;
                this.list= res.data.list;
              }
            })
      }else if(this.activeName == 2) {
        // 随访
        this.page.type = '1'
        this.$get("operate/follows/page",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.total = res.data.total;
                this.list= res.data.list;
              }
            })
      }else if(this.activeName == 3) {
        // 随访服务
        this.page.type = '2'
        this.$get("operate/follows/page",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.total = res.data.total;
                this.list= res.data.list;
              }
            })
      }
    },
    // 跳转详情
    linkDeta(e) {

      if(this.activeName == 0 || this.activeName == 2 ) {
        this.$router.push({
          path: "/OperateDeta",
          query: {
            id: e.id,
            state: this.activeName
          }
        })
      }else {
        this.$router.push({
          path: "/OperateDetaRecord",
          query: {
            id: e.id,
            state: this.activeName
          }
        })
      }

    },
    // 取消认领
    noClaim(e) {
      if(this.activeName == 0) {

        this.$post("operate/appointment/unbind/" + e)
          .then(res => {
            if(res) {
              console.log(res);
              this.page.pageNum= 1;
              this.get_list(this.page);
            }
          })

      }else if(this.activeName == 2) {
        this.$post("operate/follow/unbind/" + e)
            .then(res => {
              if(res) {
                console.log(res);
                this.page.pageNum= 1;
                this.get_list(this.page);
              }
            })
      }
    },
    handleCurrentChange(e) {
      console.log(`第${e}页`);
      this.page.pageNum= e;
      this.get_list(this.page);
    },
    handleSizeChange(e) {
      console.log(`每页${e}条`);
      this.page.pageNum= 1;
      this.page.pageSize= e;
      this.get_list(this.page);
    },
    handleClick() {
      this.page.pageNum= 1;
      this.page.pageSize= 8;
      this.get_list(this.page);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../style/Service/card";
</style>
